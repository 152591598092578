import { merge } from 'lodash'
import contentSettings from '~/sites/kolorky/content.settings'

const overloadSettings = {
  components: {
    FooterContent: {
      showLocation: false,
    },
  },
}

export default merge(contentSettings, overloadSettings)
