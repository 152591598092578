import gql from 'graphql-tag'

export const actions = {
  async LOAD_TESTIMONIALS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            testimonial {
              entities {
                title
                subtitle
                link {
                  ... on link_embedded {
                    title
                    link_type
                    custom_link
                    category_link {
                      entity {
                        ... on StoreCategory {
                          data
                        }
                      }
                    }
                  }
                }
                description
                domain_enabled
                _id
              }
            }
          }
        `,
      })
      return query.data.testimonial.entities
    } catch (e) {
      console.error(e)
    }
  },
}
