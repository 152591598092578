import moment from 'moment'

import 'moment/locale/en-gb'

import 'moment-timezone'

moment.locale('en-gb')

moment.tz.setDefault('Europe/Paris')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
