import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e43896b = () => interopDefault(import('../sites/kolorkycom/pages/about/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/about/index" */))
const _1fe617dc = () => interopDefault(import('../sites/kolorkycom/pages/user/activate.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/activate" */))
const _68b44cdc = () => interopDefault(import('../sites/kolorkycom/pages/articleList/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/articleList/index" */))
const _25ab86fe = () => interopDefault(import('../sites/kolorkycom/pages/checkout/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/index" */))
const _03148d00 = () => interopDefault(import('../sites/kolorkycom/pages/compare.vue' /* webpackChunkName: "sites/kolorkycom/pages/compare" */))
const _bc7d0d04 = () => interopDefault(import('../sites/kolorkycom/pages/contact/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/contact/index" */))
const _103bbe74 = () => interopDefault(import('../sites/kolorkycom/pages/faq/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/faq/index" */))
const _781f019a = () => interopDefault(import('../sites/kolorkycom/pages/passwordForgotten.vue' /* webpackChunkName: "sites/kolorkycom/pages/passwordForgotten" */))
const _0e6ccec4 = () => interopDefault(import('../sites/kolorkycom/pages/login.vue' /* webpackChunkName: "sites/kolorkycom/pages/login" */))
const _18aeaf2e = () => interopDefault(import('../sites/kolorkycom/pages/maintenance.vue' /* webpackChunkName: "sites/kolorkycom/pages/maintenance" */))
const _6662a398 = () => interopDefault(import('../sites/kolorkycom/pages/register.vue' /* webpackChunkName: "sites/kolorkycom/pages/register" */))
const _adee6b62 = () => interopDefault(import('../sites/kolorkycom/pages/passwordReset.vue' /* webpackChunkName: "sites/kolorkycom/pages/passwordReset" */))
const _51e8f020 = () => interopDefault(import('../sites/kolorkycom/pages/search/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/search/index" */))
const _d5042cdc = () => interopDefault(import('../sites/kolorkycom/pages/sitemapconfig.vue' /* webpackChunkName: "sites/kolorkycom/pages/sitemapconfig" */))
const _136a19c3 = () => interopDefault(import('../sites/kolorkycom/pages/user/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/index" */))
const _6e4b038b = () => interopDefault(import('../sites/kolorkycom/pages/warrantyClaims/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/warrantyClaims/index" */))
const _487a6d9c = () => interopDefault(import('../sites/kolorkycom/pages/checkout/info.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/info" */))
const _66f04cf6 = () => interopDefault(import('../sites/kolorkycom/pages/checkout/finished.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/finished" */))
const _28353118 = () => interopDefault(import('../sites/kolorkycom/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/importOrder/index" */))
const _219164de = () => interopDefault(import('../sites/kolorkycom/pages/checkout/methods.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/methods" */))
const _2251fe3a = () => interopDefault(import('../sites/kolorkycom/pages/user/addressList.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/addressList" */))
const _590fc5ba = () => interopDefault(import('../sites/kolorkycom/pages/user/password.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/password" */))
const _1e515ac9 = () => interopDefault(import('../sites/kolorkycom/pages/user/favourites.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/favourites" */))
const _33e17247 = () => interopDefault(import('../sites/kolorkycom/pages/user/orders/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/orders/index" */))
const _ed1c53f6 = () => interopDefault(import('../sites/kolorkycom/pages/user/dashboard.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/dashboard" */))
const _571cc342 = () => interopDefault(import('../sites/kolorkycom/pages/user/settings.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/settings" */))
const _02a3f4fe = () => interopDefault(import('../sites/kolorkycom/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/shoppingLists/index" */))
const _31cbb41b = () => interopDefault(import('../sites/kolorkycom/pages/warrantyClaims/form.vue' /* webpackChunkName: "sites/kolorkycom/pages/warrantyClaims/form" */))
const _202b0705 = () => interopDefault(import('../sites/kolorkycom/pages/warrantyClaims/info.vue' /* webpackChunkName: "sites/kolorkycom/pages/warrantyClaims/info" */))
const _7cc40610 = () => interopDefault(import('../sites/kolorkycom/pages/warrantyClaims/recap.vue' /* webpackChunkName: "sites/kolorkycom/pages/warrantyClaims/recap" */))
const _1ad84040 = () => interopDefault(import('../sites/kolorkycom/pages/warrantyClaims/thanks.vue' /* webpackChunkName: "sites/kolorkycom/pages/warrantyClaims/thanks" */))
const _e7784e9c = () => interopDefault(import('../sites/kolorkycom/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/kolorkycom/pages/checkout/payment/callback" */))
const _44c52bad = () => interopDefault(import('../sites/kolorkycom/pages/index.vue' /* webpackChunkName: "sites/kolorkycom/pages/index" */))
const _67e55a6f = () => interopDefault(import('../sites/kolorkycom/pages/user/orders/_id.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/orders/_id" */))
const _455d17ae = () => interopDefault(import('../sites/kolorkycom/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/kolorkycom/pages/user/shoppingLists/_id" */))
const _72e7ed8c = () => interopDefault(import('../sites/kolorkycom/pages/article/_slug.vue' /* webpackChunkName: "sites/kolorkycom/pages/article/_slug" */))
const _6c218f6c = () => interopDefault(import('../sites/kolorkycom/pages/articleList/_slug.vue' /* webpackChunkName: "sites/kolorkycom/pages/articleList/_slug" */))
const _62ee4a3f = () => interopDefault(import('../sites/kolorkycom/pages/page/_slug.vue' /* webpackChunkName: "sites/kolorkycom/pages/page/_slug" */))
const _3b9c0a45 = () => interopDefault(import('../sites/kolorkycom/pages/product/_slug.vue' /* webpackChunkName: "sites/kolorkycom/pages/product/_slug" */))
const _b222e38c = () => interopDefault(import('../sites/kolorkycom/pages/_.vue' /* webpackChunkName: "sites/kolorkycom/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _5e43896b,
    name: "about___en"
  }, {
    path: "/activate",
    component: _1fe617dc,
    name: "user-activate___en"
  }, {
    path: "/articles",
    component: _68b44cdc,
    name: "articleList___en"
  }, {
    path: "/cart",
    component: _25ab86fe,
    name: "checkout___en"
  }, {
    path: "/compare",
    component: _03148d00,
    name: "compare___en"
  }, {
    path: "/contact",
    component: _bc7d0d04,
    name: "contact___en"
  }, {
    path: "/faq",
    component: _103bbe74,
    name: "faq___en"
  }, {
    path: "/forgotten-password",
    component: _781f019a,
    name: "passwordForgotten___en"
  }, {
    path: "/login",
    component: _0e6ccec4,
    name: "login___en"
  }, {
    path: "/maintenance",
    component: _18aeaf2e,
    name: "maintenance___en"
  }, {
    path: "/register",
    component: _6662a398,
    name: "register___en"
  }, {
    path: "/reset-password",
    component: _adee6b62,
    name: "passwordReset___en"
  }, {
    path: "/search",
    component: _51e8f020,
    name: "search___en"
  }, {
    path: "/sitemapconfig",
    component: _d5042cdc,
    name: "sitemapconfig___en"
  }, {
    path: "/user",
    component: _136a19c3,
    name: "user___en"
  }, {
    path: "/warrantyClaims",
    component: _6e4b038b,
    name: "warrantyClaims___en"
  }, {
    path: "/cart/delivery-details",
    component: _487a6d9c,
    name: "checkout-info___en"
  }, {
    path: "/cart/finish",
    component: _66f04cf6,
    name: "checkout-finished___en"
  }, {
    path: "/cart/import-order",
    component: _28353118,
    name: "checkout-importOrder___en"
  }, {
    path: "/cart/shipping-and-payment",
    component: _219164de,
    name: "checkout-methods___en"
  }, {
    path: "/user/address-list",
    component: _2251fe3a,
    name: "user-addressList___en"
  }, {
    path: "/user/change-password",
    component: _590fc5ba,
    name: "user-password___en"
  }, {
    path: "/user/favourites",
    component: _1e515ac9,
    name: "user-favourites___en"
  }, {
    path: "/user/orders",
    component: _33e17247,
    name: "user-orders___en"
  }, {
    path: "/user/overview",
    component: _ed1c53f6,
    name: "user-dashboard___en"
  }, {
    path: "/user/settings",
    component: _571cc342,
    name: "user-settings___en"
  }, {
    path: "/user/shopping-lists",
    component: _02a3f4fe,
    name: "user-shoppingLists___en"
  }, {
    path: "/warrantyClaims/form",
    component: _31cbb41b,
    name: "warrantyClaims-form___en"
  }, {
    path: "/warrantyClaims/info",
    component: _202b0705,
    name: "warrantyClaims-info___en"
  }, {
    path: "/warrantyClaims/recap",
    component: _7cc40610,
    name: "warrantyClaims-recap___en"
  }, {
    path: "/warrantyClaims/thanks",
    component: _1ad84040,
    name: "warrantyClaims-thanks___en"
  }, {
    path: "/checkout/payment/callback",
    component: _e7784e9c,
    name: "checkout-payment-callback___en"
  }, {
    path: "/",
    component: _44c52bad,
    name: "index___en"
  }, {
    path: "/user/orders/:id",
    component: _67e55a6f,
    name: "user-orders-id___en"
  }, {
    path: "/user/shopping-lists/:id",
    component: _455d17ae,
    name: "user-shoppingLists-id___en"
  }, {
    path: "/article/:slug",
    component: _72e7ed8c,
    name: "article-slug___en"
  }, {
    path: "/articles/:slug",
    component: _6c218f6c,
    name: "articleList-slug___en"
  }, {
    path: "/page/:slug",
    component: _62ee4a3f,
    name: "page-slug___en"
  }, {
    path: "/product/:slug",
    component: _3b9c0a45,
    name: "product-slug___en"
  }, {
    path: "/*",
    component: _b222e38c,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
